import { RecommendationsSliderTypeKey } from 'corgi-types';
import { useLocalization } from '../../../common/providers/LocaleProvider';
export var useSliderTitle = function (type, title) {
    var t = useLocalization().t;
    if (title) {
        return title;
    }
    else if (type === RecommendationsSliderTypeKey.Cart) {
        return t('h24_you_might_like_these');
    }
    return null;
};
