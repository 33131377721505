var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { isClient } from '../../../common/utils/isClient';
import { useMatchesViewport } from './../../../common/hooks/media';
var doesValueExist = function (value, index) {
    return index in value;
};
var getClosestValue = function (value, index) {
    for (var i = index; i <= value.length; i--) {
        if (doesValueExist(value, i)) {
            return value[i];
        }
    }
    return value[0];
};
var getClosestConfig = function (_a, index) {
    var width = _a.width, gap = _a.gap;
    var closestWidth = getClosestValue(width, index);
    var closestGap = getClosestValue(gap, index);
    return [closestWidth, closestGap];
};
var getSlideSize = function (_a) {
    var _b = _a.matches, matchesTablet = _b.matchesTablet, matchesDesktop = _b.matchesDesktop, matchesLargeDesktop = _b.matchesLargeDesktop, config = __rest(_a, ["matches"]);
    switch (true) {
        case !isClient():
            /**
             * On SSR prefer desktop sizes. This improves Calibre scores 🪄
             * {@link https://home24.atlassian.net/browse/FP-3432}.
             */
            return getClosestConfig(config, 2);
        case matchesLargeDesktop:
            return getClosestConfig(config, 3);
        case matchesDesktop:
            return getClosestConfig(config, 2);
        case matchesTablet:
            return getClosestConfig(config, 1);
        default:
            return [config.width[0], config.gap[0]];
    }
};
export var useSlideSize = function (config) {
    var matches = useMatchesViewport().matches;
    var matchesTablet = matches.matchesTablet;
    var _a = __read(getSlideSize(__assign(__assign({}, config), { matches: matches })), 2), slideWidth = _a[0], slideGap = _a[1];
    return {
        matchesTablet: matchesTablet,
        slideWidth: slideWidth,
        slideGap: slideGap,
    };
};
