var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { display, position, color } from 'styled-system';
import DiscountLabels from '../../common/components/DiscountLabels';
import WishListButton from '../../common/components/WishlistButton';
import { outline, textDecoration, themeRight, } from '../../common/theme/system-utilities';
import RecommendationSlideDetails from './RecommendationSlideDetails';
import RecommendationSlideImage from './RecommendationSlideImage';
import { useSlideClickHandler } from './hooks/handlers';
import { getListEventValue } from './utils';
var LABELS_TOP = ['sp_4', 'sp_8'];
var LABELS_LEFT = ['sp_4', 'sp_8'];
var Slide = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, position, color, outline, textDecoration);
Slide.defaultProps = {
    display: 'block',
    position: 'relative',
    color: 'sys.neutral.text.strong',
    cursor: 'pointer',
    outline: 'none',
    textDecoration: 'none',
};
var StyledWishlistButton = styled(WishListButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), position, themeRight);
StyledWishlistButton.defaultProps = {
    position: 'absolute',
    right: 'sp_0',
};
var RecommendationSlideContent = function (_a) {
    var onMouseOver = _a.onMouseOver, index = _a.index, energyClass = _a.energyClass, url = _a.url, tracking = _a.tracking, type = _a.type, labels = _a.labels, prices = _a.prices, delivery = _a.delivery, name = _a.name, sku = _a.sku, withWishlistButton = _a.withWishlistButton, dyDecisionId = _a.dyDecisionId, dySlotId = _a.dySlotId, eventDataList = _a.eventDataList, slideProps = __rest(_a, ["onMouseOver", "index", "energyClass", "url", "tracking", "type", "labels", "prices", "delivery", "name", "sku", "withWishlistButton", "dyDecisionId", "dySlotId", "eventDataList"]);
    var list = getListEventValue({ eventDataList: eventDataList, type: type });
    var _b = useSlideClickHandler({
        articleSku: sku,
        articleTracking: tracking,
        slideIndex: index,
        productEventDataListType: list,
        dyDecisionId: dyDecisionId,
        dySlotId: dySlotId,
    }), onSlideClick = _b.onSlideClick, wishListButtonId = _b.wishListButtonId;
    return (React.createElement(Slide, { "data-testid": "recommendation-slide", href: url, onClick: onSlideClick, onMouseOver: onMouseOver },
        React.createElement(DiscountLabels, { labels: labels, themeTop: LABELS_TOP, themeLeft: LABELS_LEFT }),
        withWishlistButton && (React.createElement(StyledWishlistButton, { sku: sku, tracking: tracking, id: wishListButtonId })),
        React.createElement(RecommendationSlideImage, __assign({}, slideProps, { dataTestId: "recommendation-slide-image-".concat(index), alt: name })),
        React.createElement(RecommendationSlideDetails, { name: name, prices: prices, delivery: delivery, energyClass: energyClass })));
};
export default RecommendationSlideContent;
var templateObject_1, templateObject_2;
