var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useState } from 'react';
import { ENERGY_EFFICIENCY_HASH } from '../../../common/constants/energyClass';
import dispatchTrackingEvent from '../../../common/tracking/dispatchTrackingEvent';
import { getProductEventData } from '../../../common/tracking/productEventData';
import { isElementClicked } from '../../../common/utils/event';
var SHOWN_SLIDE_THRESHOLD = 0.75;
export var useSlideClickHandler = function (_a) {
    var productEventDataListType = _a.productEventDataListType, slideIndex = _a.slideIndex, articleSku = _a.articleSku, articleTracking = _a.articleTracking, dyDecisionId = _a.dyDecisionId, dySlotId = _a.dySlotId;
    var wishListButtonId = "reco-slider-".concat(articleSku);
    var onSlideClick = useCallback(function (event) {
        if (isElementClicked(event, "#".concat(wishListButtonId))) {
            event.preventDefault();
            return;
        }
        dispatchTrackingEvent({
            eventCategory: 'product',
            event: 'productClick',
            eventAction: 'click',
            eventData: {
                products: [
                    getProductEventData({
                        dySlotId: dySlotId,
                        dyDecisionId: dyDecisionId,
                        position: slideIndex,
                        tracking: articleTracking,
                        list: productEventDataListType,
                    }),
                ],
            },
        });
    }, [slideIndex, articleSku, articleTracking, productEventDataListType]);
    return {
        wishListButtonId: wishListButtonId,
        onSlideClick: onSlideClick,
    };
};
export var useWidthChange = function (_a) {
    var itemsCount = _a.itemsCount, slideWidth = _a.slideWidth, slideGap = _a.slideGap;
    var _b = __read(useState(0), 2), shownSlides = _b[0], setShownSlides = _b[1];
    var _c = __read(useState(0), 2), width = _c[0], setWidth = _c[1];
    var getShownSlides = useCallback(function (totalWidth) {
        var slideCount = totalWidth / (slideWidth + slideGap);
        var slideRatio = (totalWidth * (slideCount % 1)) / slideCount / slideWidth;
        return Math.min(slideRatio > SHOWN_SLIDE_THRESHOLD
            ? Math.ceil(totalWidth / (slideWidth + slideGap))
            : Math.floor(totalWidth / (slideWidth + slideGap)), itemsCount);
    }, [itemsCount, slideWidth, slideGap]);
    useEffect(function () {
        setShownSlides(getShownSlides(width));
    }, [itemsCount, slideWidth, slideGap, width]);
    var handleWidthChange = function (updatedWidth) {
        if (updatedWidth) {
            setWidth(updatedWidth);
        }
    };
    return { shownSlides: shownSlides, handleWidthChange: handleWidthChange };
};
export var useEnergyLabelHover = function (url) {
    var _a = __read(useState(false), 2), energyLabelHover = _a[0], setEnergyLabelHover = _a[1];
    var DATA_TESTID = 'article-energy';
    var urlWithHash = "".concat(url).concat(energyLabelHover ? ENERGY_EFFICIENCY_HASH : '');
    var handleMouseOver = useCallback(function (event) {
        setEnergyLabelHover(event.target.dataset.testid === DATA_TESTID);
    }, []);
    return { urlWithHash: urlWithHash, handleMouseOver: handleMouseOver };
};
