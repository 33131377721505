var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useEnergyLabelHover } from './hooks/handlers';
import RecommendationSlideContent from './RecommendationSlideContent';
var RecommendationSlideWithEnergyClass = function (props) {
    var _a = useEnergyLabelHover(props.url), urlWithHash = _a.urlWithHash, handleMouseOver = _a.handleMouseOver;
    return (React.createElement(RecommendationSlideContent, __assign({}, props, { onMouseOver: handleMouseOver, url: urlWithHash })));
};
export default RecommendationSlideWithEnergyClass;
