var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getConfig } from '../../utils';
import { useSlideSize } from '../slideSize';
export var useSliderConfig = function (_a) {
    var position = _a.position, withBackground = _a.withBackground, isObserved = _a.isObserved;
    var config = getConfig(position, withBackground);
    var slideSize = useSlideSize(config);
    return __assign(__assign({}, slideSize), { isObserved: isObserved, widthConfig: config.width, gapConfig: config.gap });
};
