var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { useEffect, useMemo } from 'react';
import { EventAction, EventCategory, ProductEvent, } from '../../../common/enums/Tracking';
import { articleToTrackingProps } from '../../../common/tracking/articleToTrackingProps';
import dispatchTrackingEvent from '../../../common/tracking/dispatchTrackingEvent';
import { getProductEventData } from '../../../common/tracking/productEventData';
import { getListEventValue } from '../utils';
var getUntrackedSkuIndexPairs = function (_a) {
    var items = _a.items, shownSkus = _a.shownSkus, activeSlideIndex = _a.activeSlideIndex, shownSlides = _a.shownSlides;
    var shownSkuIndexPairs = items
        .slice(activeSlideIndex, activeSlideIndex + shownSlides)
        .map(function (_a, index) {
        var articleSku = _a.article.sku;
        return ({
            articleSku: articleSku,
            index: index + activeSlideIndex,
        });
    });
    return shownSkuIndexPairs.filter(function (_a) {
        var articleSku = _a.articleSku;
        return !shownSkus.has(articleSku);
    });
};
var getProductViewImpressions = function (_a) {
    var type = _a.type, items = _a.items, eventDataList = _a.eventDataList, skuIndexPairsUntracked = _a.skuIndexPairsUntracked;
    var list = getListEventValue({ eventDataList: eventDataList, type: type });
    return skuIndexPairsUntracked.map(function (_a) {
        var index = _a.index;
        return getProductEventData(__assign(__assign({ list: list, position: index, tracking: articleToTrackingProps(items[index].article) }, (items[index].dyDecisionId && {
            dyDecisionId: items[index].dyDecisionId,
        })), (items[index].dySlotId && {
            dySlotId: items[index].dySlotId,
        })));
    });
};
export var useProductViewEventTracker = function (_a) {
    var type = _a.type, items = _a.items, activeSlideIndex = _a.activeSlideIndex, shownSlides = _a.shownSlides, eventDataList = _a.eventDataList, isObserved = _a.isObserved;
    var shownSkus = useMemo(function () { return new Set(); }, []);
    var skuIndexPairsUntracked = useMemo(function () {
        return getUntrackedSkuIndexPairs({
            items: items,
            shownSkus: shownSkus,
            activeSlideIndex: activeSlideIndex,
            shownSlides: shownSlides,
        });
    }, [items, shownSkus, activeSlideIndex, shownSlides]);
    var productViewImpressions = useMemo(function () {
        return getProductViewImpressions({
            type: type,
            items: items,
            eventDataList: eventDataList,
            skuIndexPairsUntracked: skuIndexPairsUntracked,
        });
    }, [type, items, eventDataList, skuIndexPairsUntracked]);
    useEffect(function () {
        var e_1, _a;
        if (isObserved) {
            if (productViewImpressions.length > 0) {
                dispatchTrackingEvent({
                    eventCategory: EventCategory.Product,
                    eventAction: EventAction.View,
                    event: ProductEvent.View,
                    eventData: {
                        products: productViewImpressions,
                    },
                });
                try {
                    for (var skuIndexPairsUntracked_1 = __values(skuIndexPairsUntracked), skuIndexPairsUntracked_1_1 = skuIndexPairsUntracked_1.next(); !skuIndexPairsUntracked_1_1.done; skuIndexPairsUntracked_1_1 = skuIndexPairsUntracked_1.next()) {
                        var articleSku = skuIndexPairsUntracked_1_1.value.articleSku;
                        shownSkus.add(articleSku);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (skuIndexPairsUntracked_1_1 && !skuIndexPairsUntracked_1_1.done && (_a = skuIndexPairsUntracked_1.return)) _a.call(skuIndexPairsUntracked_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }
    }, [isObserved, productViewImpressions, skuIndexPairsUntracked]);
};
