var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useRef, useEffect, useCallback } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { width } from 'styled-system';
var WidthElement = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", "\n"], ["\n  ", ";\n  ", "\n"])), width, function (_a) {
    var _b = _a.overflowHidden, overflowHidden = _b === void 0 ? false : _b;
    return overflowHidden && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      overflow: hidden;\n    "], ["\n      overflow: hidden;\n    "])));
});
WidthElement.defaultProps = {
    width: 1,
};
var GetElementWidthEnabled = function (_a) {
    var onWidthChange = _a.onWidthChange, children = _a.children, overflowHidden = _a.overflowHidden;
    var elementRef = useRef(null);
    var onWidthChangeRef = useRef(onWidthChange);
    onWidthChangeRef.current = onWidthChange;
    useEffect(function () {
        var onResize = function () {
            if (elementRef.current) {
                onWidthChangeRef.current(elementRef.current.getBoundingClientRect().width);
            }
        };
        window.addEventListener('resize', onResize);
        return function () {
            window.removeEventListener('resize', onResize);
        };
    }, []);
    var setElementRef = useCallback(function (element) {
        elementRef.current = element;
        onWidthChangeRef.current(element ? element.getBoundingClientRect().width : null);
    }, []);
    return (React.createElement(WidthElement, { overflowHidden: overflowHidden, ref: setElementRef }, children));
};
var GetElementWidth = function (_a) {
    var disabled = _a.disabled, children = _a.children, overflowHidden = _a.overflowHidden, onWidthChange = _a.onWidthChange;
    return disabled ? (React.createElement(WidthElement, { overflowHidden: overflowHidden }, children)) : (React.createElement(GetElementWidthEnabled, { overflowHidden: overflowHidden, onWidthChange: onWidthChange }, children));
};
export default GetElementWidth;
var templateObject_1, templateObject_2;
