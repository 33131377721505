var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { display, size } from 'styled-system';
import ResponsiveImage from '../../common/components/ResponsiveImage';
var RecommendationImage = styled(ResponsiveImage)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), display, size);
RecommendationImage.defaultProps = {
    display: 'block',
};
var RecommendationSlideImage = function (_a) {
    var path = _a.path, alt = _a.alt, width = _a.width, dataTestId = _a.dataTestId, widths = _a.widthConfig, isLazy = _a.isLazy;
    return (React.createElement(RecommendationImage, __assign({ "data-testid": dataTestId, alt: alt, size: width !== null && width !== void 0 ? width : widths, src: path, isLazy: isLazy }, (widths && { widths: widths }))));
};
export default RecommendationSlideImage;
var templateObject_1;
