var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import RecommendationSlideContent from './RecommendationSlideContent';
import RecommendationSlideWithEnergyClass from './RecommendationSlideWithEnergyClass';
var RecommendationSlide = function (props) {
    return props.energyClass ? (React.createElement(RecommendationSlideWithEnergyClass, __assign({}, props))) : (React.createElement(RecommendationSlideContent, __assign({}, props)));
};
export default RecommendationSlide;
