var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import styled from '@emotion/styled';
import { borderBottom, borderColor, overflow, space } from 'styled-system';
import ContainerWithBackground from '../../common/components/ContainerWithBackground';
import { RecoSliderPosition } from '../../common/enums/RecommendationsSlider';
import { useIsObserved } from '../../common/hooks/intersection-observer';
import RecommendationsSliderStatic from './RecommendationsSliderStatic';
import { useSliderConfig } from './hooks/recommendations/config';
import RecommendationsSliderLazy from './recommendations-slider.lazy';
var RecommendationsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), overflow, borderBottom, borderColor);
RecommendationsContainer.defaultProps = {
    overflow: 'hidden',
};
var StyledContainerWithBackground = styled(ContainerWithBackground)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), space, overflow);
StyledContainerWithBackground.defaultProps = __assign(__assign({}, RecommendationsContainer.defaultProps), ContainerWithBackground.defaultProps);
var isLazy = function (props) {
    return 'sku' in props || 'variables' in props;
};
var getSlider = function (props, config) {
    return isLazy(props) ? (React.createElement(RecommendationsSliderLazy, __assign({}, props, { config: config, isLazy: true }))) : (React.createElement(RecommendationsSliderStatic, __assign({}, props, { config: config })));
};
var getContainer = function (withBackground) {
    return withBackground ? StyledContainerWithBackground : RecommendationsContainer;
};
var getBorderProps = function (withSeparator) {
    return withSeparator
        ? {
            borderBottom: '1px solid',
            borderColor: 'sys.neutral.border.weak',
        }
        : null;
};
var RecommendationsSlider = function (_a) {
    var _b = _a.position, position = _b === void 0 ? RecoSliderPosition.Default : _b, props = __rest(_a, ["position"]);
    var className = props.className, withBackground = props.withBackground, withSeparator = props.withSeparator;
    var _c = __read(useIsObserved(), 2), ref = _c[0], isObserved = _c[1];
    var config = useSliderConfig({
        position: position,
        withBackground: withBackground,
        isObserved: isObserved,
    });
    var Container = getContainer(withBackground);
    var handleTouchEnd = function (event) {
        event.stopPropagation();
    };
    return (React.createElement(Container, __assign({ ref: ref, className: className, onTouchEnd: handleTouchEnd, "data-testid": "recommendations-slider" }, getBorderProps(withSeparator)), getSlider(props, config)));
};
export default RecommendationsSlider;
var templateObject_1, templateObject_2;
