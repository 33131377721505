var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import GetElementWidth from '../../common/components/GetElementWidth';
import { articleToTrackingProps } from '../../common/tracking/articleToTrackingProps';
import { useWidthChange } from './hooks/handlers';
import { useSliderTitle } from './hooks/title';
import { useProductViewEventTracker } from './hooks/tracking';
import RecommendationsCarousel from './RecommendationsCarousel';
var recommendationArticleToTrackingProps = function (_a) {
    var campaigns = _a.campaigns, brand = _a.brand, trackingId = _a.trackingId, article = __rest(_a, ["campaigns", "brand", "trackingId"]);
    return articleToTrackingProps(__assign(__assign({}, article), { campaigns: campaigns || [], brand: brand || null, trackingId: trackingId || null }));
};
var getTransformedItems = function (items) {
    return items.map(function (item) { return (__assign(__assign({}, item), { article: __assign(__assign({}, item.article), { tracking: recommendationArticleToTrackingProps(item.article) }) })); });
};
var RecommendationsSliderStatic = function (_a) {
    var type = _a.type, initialTitle = _a.title, initialItems = _a.items, withWishlistButton = _a.withWishlistButton, eventDataList = _a.eventDataList, isLazy = _a.isLazy, _b = _a.config, widthConfig = _b.widthConfig, slideWidth = _b.slideWidth, slideGap = _b.slideGap, isObserved = _b.isObserved, gapConfig = _b.gapConfig;
    var items = getTransformedItems(initialItems);
    var _c = __read(useState(0), 2), activeSlideIndex = _c[0], setActiveSlideIndex = _c[1];
    var _d = useWidthChange({
        itemsCount: items.length,
        slideWidth: slideWidth,
        slideGap: slideGap,
    }), shownSlides = _d.shownSlides, handleWidthChange = _d.handleWidthChange;
    var title = useSliderTitle(type, initialTitle);
    useProductViewEventTracker({
        type: type,
        items: items,
        activeSlideIndex: activeSlideIndex,
        shownSlides: shownSlides,
        eventDataList: eventDataList,
        isObserved: isObserved,
    });
    if (!items.length) {
        return null;
    }
    return (React.createElement(GetElementWidth, { onWidthChange: handleWidthChange },
        React.createElement(RecommendationsCarousel, { slides: items, widthConfig: widthConfig, gapConfig: gapConfig, width: slideWidth, slideGap: slideGap, onAfterSlide: setActiveSlideIndex, type: type, withWishlistButton: withWishlistButton, eventDataList: eventDataList, title: title, isLazy: isLazy })));
};
export default RecommendationsSliderStatic;
