export var CONFIG_DEFAULT = {
    width: [132, 200, 248, 228],
    gap: [14, 16, 26, 26],
};
export var CONFIG_DRAWER = {
    width: [120, 136, 144, 200],
    gap: [16, 20, 24, 32],
};
export var CONFIG_WITH_BACKGROUND = {
    width: [120, 136, 144, 200],
    gap: [16, 16, 16, 24],
};
export var DY_SELECTOR = 'H24_{LOCALE}_x_AddToCart_Reco';
