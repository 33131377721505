var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { width } from 'styled-system';
import Carousel from '../../common/components/Carousel';
import { Flex } from '../../common/components/Flex';
import { articleToTrackingProps } from '../../common/tracking/articleToTrackingProps';
import RecommendationSlide from './RecommendationSlide';
var SliderHeader = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
SliderHeader.defaultProps = {
    alignItems: 'center',
    mb: 'sp_24',
};
var SlideContainer = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), width);
var RecommendationsCarousel = function (_a) {
    var widthConfig = _a.widthConfig, gapConfig = _a.gapConfig, slides = _a.slides, onAfterSlide = _a.onAfterSlide, type = _a.type, withWishlistButton = _a.withWishlistButton, eventDataList = _a.eventDataList, title = _a.title, isLazy = _a.isLazy;
    return (React.createElement(Carousel, { afterSlide: onAfterSlide, slideGap: gapConfig, title: title, withTopControls: true }, slides.map(function (_a, index) {
        var article = _a.article, dyParms = __rest(_a, ["article"]);
        return (React.createElement(SlideContainer, { key: index, width: widthConfig },
            React.createElement(RecommendationSlide, __assign({}, article, { tracking: articleToTrackingProps(article) }, dyParms, { withWishlistButton: withWishlistButton, key: "recommendation-slide-".concat(index), path: article.images[0].path, widthConfig: widthConfig, index: index, type: type, eventDataList: eventDataList, isLazy: isLazy }))));
    })));
};
export default RecommendationsCarousel;
var templateObject_1, templateObject_2;
