var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { alignSelf, display, justifyContent, overflow, position, space, textAlign, } from 'styled-system';
import ArticleSmallPrice, { RenderingStrategy, } from '../../common/components/Price/ArticleSmallPrice';
import { Text } from '../../common/components/Text';
import { useLocalization } from '../../common/providers/LocaleProvider';
import { textOverflow, whiteSpace } from '../../common/theme/system-utilities';
var RecommendationSlideDetailsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", ";\n"], ["\n  ", "\n  ", ";\n"])), space, textAlign);
RecommendationSlideDetailsContainer.defaultProps = {
    mt: 'sp_8',
    textAlign: 'center',
};
var ArticleName = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), whiteSpace, overflow, textOverflow);
ArticleName.defaultProps = {
    color: 'sys.neutral.text.active',
    fontSize: ['fs_14', null, null, 'fs_18'],
    lineHeight: ['lh_20', null, null, 'lh_28'],
    mb: ['sp_4', null, 'sp_8'],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};
var PriceContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), display, justifyContent);
PriceContainer.defaultProps = {
    display: 'flex',
    justifyContent: 'center',
};
var FastDeliveryContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), space, textAlign);
FastDeliveryContainer.defaultProps = {
    mt: 'sp_4',
};
var FastDeliveryMessage = styled(Text)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), alignSelf, position);
FastDeliveryMessage.defaultProps = __assign(__assign({}, Text.defaultProps), { as: 'span', fontSize: ['fs_12', null, null, 'fs_18'], lineHeight: ['lh_16', null, null, 'lh_28'], color: 'sys.success.text.default', alignSelf: 'center', position: 'relative' });
var RecommendationSlideDetails = function (_a) {
    var _b;
    var name = _a.name, _c = _a.prices, regular = _c.regular, special = _c.special, rrp = _c.rrp, measurement = _c.measurement, delivery = _a.delivery, energyClass = _a.energyClass;
    var t = useLocalization().t;
    return (React.createElement(RecommendationSlideDetailsContainer, null,
        React.createElement(ArticleName, { "data-testid": "recommendation-slide-name" }, name),
        React.createElement(PriceContainer, null,
            React.createElement(ArticleSmallPrice, { energyClass: energyClass, specialPrice: special, rrpPrice: rrp, regularPrice: regular, measurement: measurement, renderingStrategy: RenderingStrategy.Vertical })),
        ((_b = delivery === null || delivery === void 0 ? void 0 : delivery.flags) === null || _b === void 0 ? void 0 : _b.fastDelivery) && (React.createElement(FastDeliveryContainer, { "data-testid": "fast-delivery" },
            React.createElement(FastDeliveryMessage, null, t('h24_immediately_available'))))));
};
export default RecommendationSlideDetails;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
