import { ProductEventDataList, RecommendationsSliderTypeKey as Type, } from 'corgi-types';
import { RecoSliderPosition } from '../../common/enums/RecommendationsSlider';
import { CONFIG_DEFAULT, CONFIG_DRAWER, CONFIG_WITH_BACKGROUND, } from './constants';
/**
 * @todo replace this map by corgi component list prop
 * https://home24.atlassian.net/browse/FP-2612
 */
export var getListEventValue = function (_a) {
    var eventDataList = _a.eventDataList, type = _a.type;
    if (eventDataList) {
        return eventDataList;
    }
    if (!type) {
        return undefined;
    }
    return new Map([
        [Type.CartEmpty, ProductEventDataList.CartEmptyRecoSlider],
        [Type.Cart, ProductEventDataList.CartRecoSlider],
        [Type.Homepage, ProductEventDataList.Homepage],
        [Type.ShopTheLook, ProductEventDataList.ShopTheLook],
        [Type.PlpOne, ProductEventDataList.PlpRecoSliderOne],
        [Type.PlpTwo, ProductEventDataList.PlpRecoSliderTwo],
        [Type.PdpOne, ProductEventDataList.PdpRecoSliderOne],
        [Type.PdpTwo, ProductEventDataList.PdpRecoSliderTwo],
        [Type.PdpThree, ProductEventDataList.PdpRecoSliderThree],
        [Type.PdpFour, ProductEventDataList.PdpRecoSliderFour],
        [Type.SearchNoResults, ProductEventDataList.SearchNoResultsRecoSlider],
        [Type.HelpSubmission, ProductEventDataList.HelpSubmissionRecoSlider],
        [Type.Wishlist, ProductEventDataList.WishlistRecoSlider],
        [Type.WishlistNoResults, ProductEventDataList.WishlistNoResultsRecoSlider],
        [Type.AddToCart, ProductEventDataList.AddToCartRecoSlider],
    ]).get(type);
};
export var getConfig = function (position, withBackground) {
    if (withBackground) {
        return CONFIG_WITH_BACKGROUND;
    }
    return position === RecoSliderPosition.Default
        ? CONFIG_DEFAULT
        : CONFIG_DRAWER;
};
